<template>
  <v-container id="table-merchandises-items" fluid tag="section">
    <v-card>
      <v-card-title>
        {{ $t('purchase.merchandise.items.' + String(type) + '.title') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('purchase.merchandise.items.table.search')" single-line hide-details />
        <v-spacer />
        <table-exporter :headers="filteredHeaders" :items="merchandiseItems" />
        <table-printer element-id="table-merchandise-items" />
      </v-card-title>
      <v-data-table :headers="filteredHeaders" :options="table.options" :items="merchandiseItems" :search="table.search" :loading="loading" :item-class="formatRowBackground" hide-default-footer dense
                    :mobile-breakpoint="merchandiseId === undefined ? 1024 : 0"
      >
        <template #[`item.type`]="{ item }">
          <span>{{ $t('purchase.merchandise.items.type.' + String(item.type)) }}</span>
        </template>
        <template #[`item.unitPriceTaxExcluded`]="{ item }">
          <span>{{ formatUnitPriceTaxExcluded(item.unitPriceTaxExcluded) }}</span>
        </template>
        <template #[`item.origin`]="{ item }">
          <span>{{ formatOrigin(item.origin) }}</span>
        </template>
        <template #[`item.expiresAt`]="{ item }">
          <span>{{ formatDate(item.expiresAt) }}</span>
        </template>
        <template #[`item.createdAt`]="{ item }">
          <span>{{ formatDate(item.createdAt) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn v-if="item.type === 'check'" small rounded color="red" :disabled="loading" :loading="loading" @click.stop="archiveMerchandiseItem(item)">
            <v-icon medium dark :title="$t('purchase.merchandise.items.form.archive.title')">
              mdi-delete
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import { displayDateISO } from '@/util/DateUtil';
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';

  export default {
    name: 'MerchandiseItems',
    components: { TableExporter, TablePrinter },
    props: {
      merchandiseId: {
        type: Number
      },
      type: {
        type: String
      },
      refresh: {
        type: Number
      },
      color: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.merchandise'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'merchandise.name'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.type'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'type'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.quantity'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'quantity'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.unitPriceTaxExcluded'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'unitPriceTaxExcluded'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.supplier'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'supplier.name'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.origin'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'origin'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.category'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'category'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.variety'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'variety'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.size'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'size'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.expiresAt'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'expiresAt'
            },
            {
              text: this.$i18n.t('purchase.merchandise.items.table.column.createdAt'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'createdAt'
            },
            {
              text: '',
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions',
              width: '120px'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: ['createdAt'],
            sortDesc: [true],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        merchandiseItems: [],
        merchandiseItem: {},
        editMerchandiseItem: false
      };
    },
    computed: {
      filteredHeaders () {
        return this.table.headers.filter((header) => {
          if (this.merchandiseId === undefined) {
            return true;
          }

          return !['merchandise.name', 'quantity', 'expiresAt', 'actions'].includes(header.value);
        });
      }
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatRowBackground (item) {
        if (!this.color || typeof item.type !== 'string') {
          return '';
        }

        return item.type;
      },
      formatUnitPriceTaxExcluded (price) {
        if (typeof price !== 'number') {
          return '';
        }

        return String(price) + this.$i18n.t('common.price.currency.EUR.symbol') + ' ' + this.$i18n.t('common.price.taxExcluded.symbol');
      },
      formatOrigin (origin) {
        if (typeof origin !== 'string') {
          return '';
        }

        return origin.toUpperCase();
      },
      formatDate: displayDateISO,
      async archiveMerchandiseItem (merchandiseItem) {
        if (confirm(this.$i18n.t('purchase.merchandise.items.form.archive.confirmation.' + String(merchandiseItem.type), { name: merchandiseItem.merchandise.name }))) {
          this.loading = true;
          try {
            await this.$http.delete(process.env.VUE_APP_BASE_API_URL + 'merchandise/item', { data: { id: merchandiseItem.id } });

            await this.refreshData();
          } catch (error) {
            this.$emit('fail', error, this.$i18n.t('common.error.default'));
          }
          this.loading = false;
        }
      },
      async refreshData () {
        this.loading = true;
        try {
          const params = [];
          if (typeof this.merchandiseId === 'number') {
            params.push('merchandiseId=' + String(this.merchandiseId));
          }
          if (typeof this.type === 'string' && this.type !== 'all') {
            params.push('type=' + String(this.type));
          }
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'merchandise/items/last' + String(params.length > 0 ? '?' + params.join('&') : ''), { timeout: 60000 });
          if (response.data !== undefined && response.data.merchandiseItems !== undefined) {
            this.merchandiseItems = response.data.merchandiseItems;
          }
          this.$emit('success');
        } catch (error) {
          this.$emit('fail', error, this.$i18n.t('common.error.default'));
        }
        this.loading = false;
      },
      fail (error) {
        this.$emit('fail', error, this.$i18n.t('common.error.default'));
      }
    }
  };
</script>
