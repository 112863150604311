var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"table-merchandises","fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('purchase.analytic.priceRecommendation.subtitle'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('purchase.analytic.priceRecommendation.table.search'),"single-line":"","hide-details":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('v-spacer'),_c('table-exporter',{attrs:{"headers":_vm.table.headers,"items":_vm.recommendations}}),_c('table-printer',{attrs:{"element-id":"table-merchandises"}})],1),_c('v-data-table',{attrs:{"headers":_vm.table.headers,"options":_vm.table.options,"items":_vm.recommendations,"search":_vm.table.search,"loading":_vm.loading,"group-by":"merchandiseDepartment","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var items = ref.items;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('span',{staticClass:"text-uppercase"},[_vm._v(_vm._s(items[0].merchandiseDepartment))])],1),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th')]}},{key:"item.merchandiseDepartment",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.merchandiseDepartment))])]}},{key:"item.merchandiseName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-bold"},[_vm._v(_vm._s(item.merchandiseName))])]}},{key:"item.maxRecommendedPriceTaxExcluded",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.formatPriceTaxExcluded(item.maxRecommendedPriceTaxExcluded, item.merchandiseUnit)))])]}},{key:"item.lastPurchasePriceTaxExcluded",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPriceTaxExcluded(item.lastPurchasePriceTaxExcluded, item.merchandiseUnit)))])]}},{key:"item.targetSellingPriceTaxExcluded",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPriceTaxExcluded(item.targetSellingPriceTaxExcluded, item.merchandiseUnit)))])]}},{key:"item.lowestCompetitorSellingPriceTaxExcluded",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPriceTaxExcluded(item.lowestCompetitorSellingPriceTaxExcluded, item.merchandiseUnit)))])]}},{key:"item.avgCompetitorSellingPriceTaxExcluded",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPriceTaxExcluded(item.avgCompetitorSellingPriceTaxExcluded, item.merchandiseUnit)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('merchandise-history',{attrs:{"merchandise-id":item.merchandiseId},on:{"fail":_vm.fail}})]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }