<template>
  <v-container class="ma-0 pa-0">
    <v-btn small color="primary" :loading="show" :disabled="show" @click.stop="show=true">
      <v-icon medium dark class="pr-2">
        mdi-history
      </v-icon>
      {{ $t('purchase.merchandise.items.history.button.show') }}
    </v-btn>
    <v-dialog v-model="show" :persistent="false" :light="true" :fullscreen="true" :scrollable="false" overlay-color="tertiary" max-width="90%" class="pa-2 elevation-0" content-class="elevation-0">
      <v-toolbar dense color="primary">
        <div class="display-2 font-weight-light text-uppercase white--text">
          {{ $t('purchase.merchandise.items.history.title') }}
        </div>
        <v-spacer />
        <v-btn icon dark @click.stop="show=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <merchandise-items type="all" :merchandise-id="merchandiseId" :refresh="refresh" :color="true" @fail="fail" />
        <v-card-actions>
          <v-spacer />
          <v-btn color="close" @click.stop="show=false">
            {{ $t('purchase.merchandise.items.history.button.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import MerchandiseItems from '@/views/components/business/purchase/MerchandiseItems';

  export default {
    name: 'MerchandiseHistory',
    components: { MerchandiseItems },
    props: {
      merchandiseId: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        show: false,
        refresh: null
      };
    },
    watch: {
      show: function (value) {
        if (value) {
          this.refresh = Date.now();
        }
      }
    },
    methods: {
      fail (error, errorMessage) {
        this.$emit('fail', error, errorMessage);
      }
    }
  };
</script>
