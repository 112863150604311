<template>
  <v-container id="table-merchandises" fluid tag="section">
    <v-card>
      <v-card-title>
        {{ $t('purchase.analytic.priceRecommendation.subtitle') }}
        <v-spacer />
        <v-text-field v-model="table.search" append-icon="mdi-magnify" :label="$t('purchase.analytic.priceRecommendation.table.search')" single-line hide-details />
        <v-spacer />
        <table-exporter :headers="table.headers" :items="recommendations" />
        <table-printer element-id="table-merchandises" />
      </v-card-title>
      <v-data-table :headers="table.headers" :options="table.options" :items="recommendations" :search="table.search" :loading="loading" group-by="merchandiseDepartment" hide-default-footer dense>
        <template #[`group.header`]="{items, isOpen, toggle}">
          <th>
            <v-icon @click="toggle">
              {{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
            </v-icon>
            <span class="text-uppercase">{{ items[0].merchandiseDepartment }}</span>
          </th>
          <th />
          <th />
          <th />
          <th />
          <th />
          <th />
          <th />
        </template>
        <template #[`item.merchandiseDepartment`]="{ item }">
          <span class="text-capitalize">{{ item.merchandiseDepartment }}</span>
        </template>
        <template #[`item.merchandiseName`]="{ item }">
          <span class="text-capitalize font-weight-bold">{{ item.merchandiseName }}</span>
        </template>
        <template #[`item.maxRecommendedPriceTaxExcluded`]="{ item }">
          <span class="font-weight-bold">{{ formatPriceTaxExcluded(item.maxRecommendedPriceTaxExcluded, item.merchandiseUnit) }}</span>
        </template>
        <template #[`item.lastPurchasePriceTaxExcluded`]="{ item }">
          <span>{{ formatPriceTaxExcluded(item.lastPurchasePriceTaxExcluded, item.merchandiseUnit) }}</span>
        </template>
        <template #[`item.targetSellingPriceTaxExcluded`]="{ item }">
          <span>{{ formatPriceTaxExcluded(item.targetSellingPriceTaxExcluded, item.merchandiseUnit) }}</span>
        </template>
        <template #[`item.lowestCompetitorSellingPriceTaxExcluded`]="{ item }">
          <span>{{ formatPriceTaxExcluded(item.lowestCompetitorSellingPriceTaxExcluded, item.merchandiseUnit) }}</span>
        </template>
        <template #[`item.avgCompetitorSellingPriceTaxExcluded`]="{ item }">
          <span>{{ formatPriceTaxExcluded(item.avgCompetitorSellingPriceTaxExcluded, item.merchandiseUnit) }}</span>
        </template>
        <template #[`item.actions`]="{ item }">
          <merchandise-history :merchandise-id="item.merchandiseId" @fail="fail" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import TableExporter from '@/views/components/common/TableExporter';
  import TablePrinter from '@/views/components/common/TablePrinter';
  import MerchandiseHistory from '@/views/components/business/purchase/MerchandiseHistory';

  export default {
    name: 'PurchasePriceRecommendation',
    components: { MerchandiseHistory, TableExporter, TablePrinter },
    props: {
      refresh: {
        type: Number
      }
    },
    data () {
      return {
        loading: false,
        table: {
          headers: [
            {
              text: this.$i18n.t('purchase.analytic.priceRecommendation.table.column.merchandiseDepartment'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'merchandiseDepartment'
            },
            {
              text: this.$i18n.t('purchase.analytic.priceRecommendation.table.column.merchandiseName'),
              align: 'center',
              filterable: true,
              sortable: true,
              value: 'merchandiseName'
            },
            {
              text: this.$i18n.t('purchase.analytic.priceRecommendation.table.column.maxRecommendedPriceTaxExcluded'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'maxRecommendedPriceTaxExcluded'
            },
            {
              text: this.$i18n.t('purchase.analytic.priceRecommendation.table.column.lastPurchasePriceTaxExcluded'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'lastPurchasePriceTaxExcluded'
            },
            {
              text: this.$i18n.t('purchase.analytic.priceRecommendation.table.column.targetSellingPriceTaxExcluded'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'targetSellingPriceTaxExcluded'
            },
            {
              text: this.$i18n.t('purchase.analytic.priceRecommendation.table.column.lowestCompetitorSellingPriceTaxExcluded'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'lowestCompetitorSellingPriceTaxExcluded'
            },
            {
              text: this.$i18n.t('purchase.analytic.priceRecommendation.table.column.avgCompetitorSellingPriceTaxExcluded'),
              align: 'center',
              filterable: false,
              sortable: true,
              value: 'avgCompetitorSellingPriceTaxExcluded'
            },
            {
              text: this.$i18n.t('purchase.analytic.priceRecommendation.table.column.actions'),
              align: 'center',
              filterable: false,
              sortable: false,
              value: 'actions'
            }
          ],
          options: {
            page: 1,
            itemsPerPage: -1,
            sortBy: ['merchandiseDepartment', 'merchandiseName'],
            sortDesc: [false, false],
            groupBy: [],
            groupDesc: [],
            multiSort: false,
            mustSort: true
          },
          search: ''
        },
        recommendations: []
      };
    },
    watch: {
      refresh: async function () {
        await this.refreshData();
      }
    },
    mounted: async function () {
      await this.refreshData();
    },
    methods: {
      formatPriceTaxExcluded (price, unit) {
        if (typeof price !== 'number') {
          return '';
        }

        return String(price) + this.$i18n.t('common.price.currency.EUR.symbol') + ' ' + this.$i18n.t('common.price.taxExcluded.symbol') + '/' + this.$i18n.t('common.unit.' + String(unit) + '.short');
      },
      async refreshData () {
        this.loading = true;
        try {
          const response = await this.$http.get(process.env.VUE_APP_BASE_API_URL + 'merchandise/price/recommendations', { timeout: 10000 });
          if (Array.isArray(response.data?.recommendations)) {
            this.recommendations = response.data.recommendations;
          }
          this.$emit('success');
        } catch (error) {
          this.fail(error, this.$i18n.t('common.error.default'));
        }
        this.loading = false;
      },
      fail (error, errorMessage) {
        this.$emit('fail', error, errorMessage);
      }
    }
  };
</script>
