<template>
  <v-container id="purchase-analytics" fluid tag="section">
    <alert-notification :message="alert" />
    <v-card color="tabBackgroundColor">
      <v-tabs color="deep-blue accent-4" background-color="transparent" centered grow show-arrows @change="onTabChange">
        <v-tab>{{ $t('purchase.analytic.priceRecommendation.title') }}</v-tab>
        <v-btn icon medium dark color="black" :loading="loading" :disabled="loading" @click.stop="refreshCurrentTab">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-tab-item key="0">
          <v-card color="tabBackgroundColor" class="ma-0 pa-0 rounded-0">
            <purchase-price-recommendation :refresh="refresh[0]" @success="success" @fail="fail" />
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
  import { handleRequestError } from '@/services/common/Http';
  import AlertNotification from '@/views/components/common/notification/AlertNotification';
  import PurchasePriceRecommendation from '@/views/components/business/purchase/PurchasePriceRecommendation';

  export default {
    name: 'PurchaseAnalytics',
    components: { AlertNotification, PurchasePriceRecommendation },
    data () {
      return {
        alert: '',
        currentTab: 1,
        loading: false,
        refresh: [null, null, null, null, null, null, null, null, null, null, null]
      };
    },
    methods: {
      refreshCurrentTab () {
        this.onTabChange(this.currentTab);
      },
      onTabChange (tab) {
        this.loading = true;
        this.refresh[tab] = Date.now();
        this.currentTab = tab;
      },
      success () {
        this.alert = '';
        this.loading = false;
      },
      async fail (error, message) {
        this.alert = await handleRequestError(error, this.$router, this.$i18n, message);
        this.loading = false;
      }
    }
  };
</script>
