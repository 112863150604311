var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"table-merchandises-items","fluid":"","tag":"section"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('purchase.merchandise.items.' + String(_vm.type) + '.title'))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('purchase.merchandise.items.table.search'),"single-line":"","hide-details":""},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('v-spacer'),_c('table-exporter',{attrs:{"headers":_vm.filteredHeaders,"items":_vm.merchandiseItems}}),_c('table-printer',{attrs:{"element-id":"table-merchandise-items"}})],1),_c('v-data-table',{attrs:{"headers":_vm.filteredHeaders,"options":_vm.table.options,"items":_vm.merchandiseItems,"search":_vm.table.search,"loading":_vm.loading,"item-class":_vm.formatRowBackground,"hide-default-footer":"","dense":"","mobile-breakpoint":_vm.merchandiseId === undefined ? 1024 : 0},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$t('purchase.merchandise.items.type.' + String(item.type))))])]}},{key:"item.unitPriceTaxExcluded",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatUnitPriceTaxExcluded(item.unitPriceTaxExcluded)))])]}},{key:"item.origin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatOrigin(item.origin)))])]}},{key:"item.expiresAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.expiresAt)))])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.createdAt)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.type === 'check')?_c('v-btn',{attrs:{"small":"","rounded":"","color":"red","disabled":_vm.loading,"loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.archiveMerchandiseItem(item)}}},[_c('v-icon',{attrs:{"medium":"","dark":"","title":_vm.$t('purchase.merchandise.items.form.archive.title')}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }